@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');


body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;

}

nav {
  .navbar-brand{
    font-family: Allerta stencil;
    font-size: larger;
  }
  button.navbar-toggler {
    border: none;
  }
  
  
  }

a.navbar-brand {
  font-size: xxx-large;
  .o {
    color: #f7fd04;
  }
}


h1 {
  color: #1b262c;
}
.devicon-devicon-plain path {
fill: #1b262c;
color: #1b262c;
}
.devicon-devicon-plain {
  max-width: 4em;
  min-width: 4em;
  min-height: 4em;
  margin: 1rem;
  fill: #1b262c;
  color: #1b262c;
  
  }
  
.allerta {
  font-family: Allerta Stencil;
}

.home{
  background-color: #1b262c;
  padding: 15%;
 
}
div.allerta.home.mx-auto {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 3em;
}

//About
.skills {
  background-color: white;
  color:#1b262c;
  margin: 10% 10% 10% 10%;
  padding: 0 5% 0 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
 

}
.section-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;
}
.break {
  min-height: 5%;
  background-color: #1b262c;
}
.about{
  background-color: white;
  color:#1b262c;
  margin: 5%;
    div.col-sm.left {
      background-color: #1b262c;
      color: white;
      padding: 4%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  }
  
  div.col-sm.right{
    padding: 4%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  h3.allerta {
    font-size: 4rem;
  }
  div.container {
    font-size: .8rem;
    margin-top: 5%;
    display: flex;
    align-items: center;
    flex-direction: column;
    p {
      margin-top: 3%;
      display: flex;
      flex-direction: column;
      align-items: center;
    
    }
    
  }
  svg.devicon-devicon-plain {
    border: solid  1px #1b262c;
    padding: 5%;
  }
  div.logos{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  div.tech {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: small;
  }
}

// Links to projects and ReadMes
div.projectlinks {
  display: flex;
  p {
    margin-right: 1em;
    color: #f7fd04;
  }
}

// Experience
.experience {
  background-color:#1b262c;
  color: white;
  padding: 5%;
  
  div.row {
    display: flex;
    align-items: center;
    margin-bottom: 5%;
    
  }
  .col-sm{
    margin-top: 1em;
    padding-left: 2em;
    
  }
  img.img-fluid{
    border-radius: 5px;
  }

  a {
    text-decoration: none;
    color: white;
    
  }
  div.projects {
    margin: 5em 0 0 0;
  }
  
}

// Previous Experience

.previous-experience {
  background-color: white;
  color:#1b262c;
  margin: 5%;
  

  div.row {
    display: flex;
    flex-direction: column;

  }
}
// Titles for setions 
h2.allerta {
  margin-bottom: 5%;
  font-size: 4rem;
}

// Interests

.interests {
  background-color: #1b262c;
  color: white;
  padding: 5%;
  
    .img-fluid {
      max-height: 20em;
      padding: 5%;
      border-radius: 32px;
    
    }
  
    div.row-run {
      padding: 2em;
      background-color:#1b262c;
      color:white;
      display: flex;
      flex-direction: row;
      align-items: center;
        p {
          padding: 5%;
        }

    }
    div.row-dog {
    padding: 2em;
    background-color:#1b262c;
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
      p {
        padding: 5%;
      }

    }
}
// Contact

.contact {
  background-color: white;
  color:#1b262c;
  margin: 5%;
  display: flex;
  flex-direction: column;
  div.row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  div.col-sm {
    max-width: 15rem;
  }
  img {
    
    border-radius: 100%;

  }
  ul {
    list-style-type: none;
    display: flex;
    justify-content: center;
    img-fluid {
      max-width: 4em;
      min-width: 1em;
      margin: 1rem;
    }
    li {
      margin-right: 1rem;
    }
  }
  
}
.fa-github {
  color:#1b262c;
} 
.fa-linkedin {
  color:#1b262c;
} 
.fa-envelope {
  color:#1b262c;
} 
